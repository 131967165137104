﻿body {
    max-width:2400px;
    margin: 0 auto;
}

.module {
    background-color:$module-bg-colour;
}

.padded-top  {padding-top: 20px} 
.center {text-align:center;} 
.right {text-align:right;}