﻿body {background-color: rgb(241,241,241)}
.ticklist ul {
	list-style: none;
	padding:0;
  }

  .ticklist ul li {
	  padding-left: 10px;
  }

  .ticklist ul li a {
	color: #000;
	text-decoration: underline;
}
  
  .ticklist ul li:before {
	content: '✓';
	color:#000;
	padding-right: 10px;
  }

  @media only screen and (min-width: 1000px) {
	.ticklist ul
	{
	  column-count: 3;
	}
  }

  @media only screen and (min-width: 800px) {
	.ticklist ul
	{
	column-count: 2;
	}
}

  .fa-footer {
    color: #fff;
}

.footer-bottom {
	a {
    color: #fff;
	text-decoration: none;
	}
	p {
		color:#fff;
	}
}

.contact-form-block {
	position:relative;


}

.contact-map {
	font-weight: bold;
}

.contact-form-block:after {
    content : "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity : 0.5;
    z-index: -1;
}


@media only screen and (max-width: 600px) {
	.logo img {
		width:100%;
	}
	.fourx-item {
		min-height:200px;
	}
	.cta-image img {
		width:100%;
	}
	.footer-right {
		text-align:center;
	}
	.footer-top {
		text-align:center;
	}
	.jumbotron {
		height:100%;
	}
	.three-x-cta p {
		min-height: 0px;
	}
	.three-x-icon-and-info i  {
		font-size: 28px;
	}
	.privacy-statement {
		width:90%;
	}
}

.margin-20-bottom {
	margin-bottom:20px;
}

.navbar-default .navbar-nav>li>a {
	font-size:16px;
}

.fourx-item a {
	text-decoration: none;
}

.image-block {
	padding-bottom:0px;
}

.alt-block-one {
	background-color: #666;
	padding: 20px 0;
}

.content-block {
	padding: 5px 15px 20px 15px;
}

.footnote {
	font-size: 25px;
	padding-bottom:5px !important;
}

.jumbotron {
	margin-bottom: 0px;
}

.jumbotron h1 {
    
    padding: 10px;
	color: #118291;
}

 .jumbotron h2, .jumbotron h3, .jumbotron h4, .jumbotron h5, .jumbotron h6 {
    
    padding: 10px;
	color: #505050;
}



.three-x-cta {
	margin-bottom:20px;
	p {
	min-height:200px;
	}
}

.three-x-cta-withimages-block {
	margin-bottom:20px;
}

.associations { 
	margin: 20px 0;
	.owl-carousel .item img {
		width:150px !important;
	}	
}

.contact-form-block {
    color: #fff;
	background-color: #742d3f ;
	//background-image: url('/media/1046/contact_bg.png');
	a {
		color: #fff;
	}
	h1,h2,h3,h4 {color: #abe6ff }
	h4 {font-size:30px;}
	.btn-primary {
		background-color: #fff;
		color:#000;
	}
}


.four-x-cta-block {
	padding-top: 20px;
}

.privacy-statement {
	font-size:10px;
}


.top-content{
	padding:20px;
}



.bottom-content{
	background-color:#999;
	padding:20px;
}

.header .fa {
	color: #118291;
	margin-right:4px;
}



.header-content-tel {
	color:#118291;
	font-size: 16px;
	text-align: center;
	a {
		color:#118291;
	}
}

.header-strapline {
	color: #118291;
    text-align: center;
    font-size: 18px;
    font-weight: 800;
}


.footer-logos {
	margin: 15px 0;
}

.cta-section {
	background-color: #999;
    padding: 20px 0 30px 0;
}

.header-top-container {
	color: #000;
	a {
		color:#000;
	}
}

.three-x-cta a {
	margin-bottom:10px;
}

.width-100 {
	width:100%;
}

.header-email {font-size:14px;}

.content-block-highlight {
	background-color: #118291;
	padding: 5px 15px 20px 15px;
	color:#fff;
	h1,h2,h3,h4,h5 {color:#10C5DE}
	p {
		color:#fff;
	}
	a {
		color: #fff;
		text-decoration: underline;
	}
	.ticklist ul li a {
		color: #fff;
	}
	.ticklist ul li:before {
		color: #fff;
	}
	
}


.p-logo {
	width:170px;
	float:left;
	padding: 0 5px 0 0 
}

.professional-logos {
	float:right;
}


@media only screen and (max-width: 400px) {
	.p-logo {
		padding:  0 ;
		width: 50%;
	}
	.header-content-tel 
	{
		text-align:center;
		margin: 0 auto;
		width: 100%;
		font-size:24px;
	}
}



@media only screen and (max-width: 600px) {
	.professional-logos {
		margin-left: 0px; 		
	}
	.p-logo {
		padding: 5px 0 5px 0 ;
		text-align: center;
	}
	.module {
		margin-bottom:20px;
	}

}

@media only screen and (max-width: 1100px) {
	.header-content-tel 
	{
		text-align:center;
		margin: 0 auto;
		width: 80%;
		margin-bottom:20px;

	}
	.logo {
		img {width: 100%}
	}
	.professional-logos {
		margin: 0 auto; 		
		float: none;
		width: 150px;
	}
	.vcard {
		text-align: center;
	}
	.footer-tel-email {
		text-align: center;
	}
}

.mobile-tap-to-call {
	display: none;
}

@media only screen and (max-width: 800px) {
	.mobile-tap-to-call {
		background-color: #F6ED22;
		color: black;
		text-align: center;
		padding: 5px;
		margin-bottom:5px;
		font-weight: bold;
		a {
			color:black;
		}
		display: block;
	}
	.header-content-tel {display:none}
	.content-block-highlight {
		p {
			font-weight: 800;
		}
	p {font-weight: 800;}
	font-weight: 800;
}


}




