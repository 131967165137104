﻿.header {
    .logo img {
        padding:10px;
    }
    h5.strapline {
        font-size: $strapline-font-size;
        padding-bottom: 10px;
        border-bottom: $strapline-colour solid 1px;
    }
    
 }
 .header-content {
        color: $header-colour;
        font-size: $header-font-size;
        padding:10px;
    }
 .header-content-address {
     font-size:24px;
 }


 .header-content-tel .fa {
     margin-right:10px;
 }



@media screen and (min-width: 768px) {
    .navbar {

        border-radius: $navbar-border-radius;
    }

}

.navbar-default .navbar-nav > li > a {
    font-size: $navbar-font-size;
}




