﻿.fa {
    color: $fa-colour;
}

.fa:hover {
    color: $fa-colour-hover;
}

hr {
    color: $hr-line-colour;
    border-color: $hr-line-colour;
    background-color: $hr-line-colour;
}


h1 {    
    font-size: 28px;
    font-weight: 400;


}