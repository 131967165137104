//Primary Settings...

//Colours
$fa-colour: #118291;
$fa-colour-hover: darken($fa-colour, 10);
$alert-colour: #118291;  
$primary-highlight: #118291;
$module-bg-colour: transparent;

//Typography Settings
$header-font: Arial , Helvetica , sans-serif;
$hr-line-colour: #549497;


$base-line-height: 1.55;  
$base-font-size-large: 18px;


//Heading Settings
$strapline-font-size: 20px;
$strapline-colour:  #000;
$header-colour: #000; 
$header-font-size: 26px;

//Navbar 
$navbar-font-size: 22px; 

//Banner Settings
$banner-bg-colour: #fff;
$banner-inner-bg-colour: #fff;
$banner-body-colour: #fff;
$banner-header-colour: #fff;
$banner-color: $primary-highlight;
$banner-color-a: #ffffff;
$banner-color-hover: darken($banner-color-a, 10);
$banner-color-visited: $banner-color-hover;
$banner-color-focus: darken($banner-color-hover, 10);

//Footer Settings
$footer-bg-colour: #686868;
$footer-inner-bg-colour: #686868;
$footer-body-colour: #fff;
$footer-header-colour: #fff;
$footer-color: $primary-highlight;
$footer-color-a: #fff;
$footer-color-hover: darken($footer-color-a, 10);
$footer-color-visited: $footer-color-hover;
$footer-color-focus: darken($footer-color-hover, 10);

//Gallery Settings
$modal-background-colour: lighten(#01213A, 2);
//Button Settings 

//default button
$btn-default-background-colour: #999;
$btn-default-border-colour:  #9c1c19;
$btn-default-colour: #000;
$btn-default-background-colour-hover: darken($btn-default-background-colour, 10);
$btn-default-border-colour-hover: darken($btn-default-border-colour, 10);
$btn-default-colour-hover: #fff;
//primary button
$btn-primary-background-colour: #118291;
$btn-primary-border-colour:  #999;
$btn-primary-colour: #fff;
$btn-primary-background-colour-hover: darken($btn-primary-background-colour, 10);
$btn-primary-border-colour-hover: darken($btn-primary-border-colour, 10);
$btn-primary-colour-hover: #000;

// // Import custom normalise and mixins


// Bootstrap
@import "_bootstrap.scss";


//libraries
    @import "libraries/aos/aos.scss";
    @import "libraries/hover/hover.scss";
    @import "libraries/magnific-popup/magnific-popup.scss";
    @import "libraries/twenty-twenty/twenty-twenty.scss";
    @import "libraries/cookie-consent/cookie-consernt.scss";

// Global partials
 
  @import "global/typography.scss";
  @import "global/layout.scss";
  @import "global/buttons.scss"; 



// Master template
    @import "layout/header.scss";
 	@import "layout/footer.scss";
 	


// Modules

 	@import "modules/banner.scss";
    @import "modules/threeXCTATextOnly.scss";
    @import "modules/threeXCTAwithImages.scss";
    @import "modules/threeXIconAndInfo.scss";
    @import "modules/fourXCTABlock.scss";
    @import "modules/counter.scss"; 
    @import "modules/priceList.scss";
    @import "modules/productPriceFeatureTable";
    @import "modules/forms.scss";



 // Custom site specific classes etc

    @import "custom/site.scss";